<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <b-table
      ref="refRentsListTable"
      class="position-relative mh-200"
      :items="fetchRents"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No rents found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Code -->
      <template #cell(code)="data">
        <div class="text-nowrap">
          <strong class="align-text-top text-capitalize">{{ data.item.code }}</strong>
        </div>
      </template>

      <template #cell(customer)="data">
        <div class="text-nowrap">
          <strong class="align-text-top">{{ data.item.customer.email }}</strong>
        </div>
      </template>

      <!-- Column: Items -->
      <template #cell(items)="data">
        <div class="text-nowrap">
          <strong class="align-text-top text-capitalize">{{ data.item.items }}</strong>
        </div>
      </template>

      <template #cell(shipment_price)="data">
        <div class="text-nowrap">
          <b-badge
            variant="info"
            class="badge"
          >
            {{ data.item.shipment_price }}€
          </b-badge>
        </div>
      </template>

      <template #cell(price)="data">
        <div class="text-nowrap">
          <b-badge
            variant="success"
            class="badge"
          >
            {{ data.item.offer && data.item.offer.price ? data.item.offer.price : data.item.price }}€
          </b-badge>
        </div>
      </template>

      <template #cell(rent_days)="data">
        <div class="text-nowrap">
          <strong class="align-text-top text-capitalize">{{ data.item.offer && data.item.offer.rent_days ? data.item.offer.rent_days : data.item.rent_days }}</strong>
        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">
          <b-badge
            :variant="statusResolver(data.item.status)"
            class="badge"
          >
            {{ data.item.status }}
          </b-badge>
        </div>
      </template>

      <template #cell(inoltrato_il)="data">
        <div class="text-nowrap">
          <strong class="align-text-top text-capitalize">{{ data.item.forwarded_on }}</strong>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-button
          v-if="$can('show', 'Rent')"
          variant="flat-primary"
          size="sm"
          :to="{name: 'rent.edit', params: { id: data.item.id }}"
        >
          <feather-icon icon="EditIcon" />
          <span
            class="align-middle ml-50"
          >Edit</span>
        </b-button>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BBadge,
  BButton,
  // BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useRentList from '@/views/rents/useRentList'

export default {
  name: 'RentsTable',
  components: {
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  setup(props, { emit }) {
    const toast = useToast()

    const {
      refRentsListTable,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      fetchRents,
      refetchData,
      getRents,
      statusResolver,
    } = useRentList()

    const updateStatus = (item, newStatus) => {
      store.dispatch('rents/updateRent', {
        id: item.id,
        change_status: newStatus,
      })
        .then(response => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      refRentsListTable,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      fetchRents,
      refetchData,
      getRents,
      statusResolver,
      updateStatus,
    }
  },
  methods: {
    swalConfirmDeleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('rent/deleteRent', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Item has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Item is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
