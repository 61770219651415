<template>
  <div style="height: inherit">

    <!-- Filters -->
    <items-list-filters
      :order-by-filter.sync="orderByFilter"
      :location-filter.sync="locationFilter"
      :price-filter.sync="priceFilter"
      :gender-filter.sync="genderFilter"
      :status-filter.sync="statusFilter"
      :rent-filter.sync="rentFilter"
      :gender-options="genders"
      :status-options="status"
      :rent-options="rentStatus"
      :prices-options="prices"
      :order-by-options="orderBy"
      :locations-options.sync="locationsOptions"
      :is-sort-dir-desc.sync="isSortDirDesc"
      :group-filter.sync="groupFilter"
      :category-filter.sync="categoryFilter"
      :sub-category-filter.sync="subCategoryFilter"
      :type-filter.sync="typeFilter"
      :filter-filter.sync="filterFilter"
      :search-query-prop.sync="searchQuery"
      :current-page-prop.sync="currentPage"
      :view-type.sync="viewType"
    />

    <items-table
      v-if="viewType === 'list'"
      :order-by-filter-prop.sync="orderByFilter"
      :location-filter-prop.sync="locationFilter"
      :price-filter-prop.sync="priceFilter"
      :gender-filter-prop.sync="genderFilter"
      :status-filter-prop.sync="statusFilter"
      :rent-filter-prop.sync="rentFilter"
      :sort-by-prop.sync="sortBy"
      :is-sort-dir-desc-prop.sync="isSortDirDesc"
      :group-filter-prop.sync="groupFilter"
      :category-filter-prop.sync="categoryFilter"
      :sub-category-filter-prop.sync="subCategoryFilter"
      :type-filter-prop.sync="typeFilter"
      :filter-filter-prop.sync="filterFilter"
      :search-query-prop.sync="searchQuery"
      :current-page-prop.sync="currentPage"
      :data-meta.sync="dataMeta"
      :total-items.sync="totalItems"
      :locations-options.sync="locationsOptions"
      :per-page="perPage"
      :table-columns="tableColumns"
      :items="getListItems()"
      @refetch-data="refetchData()"
    />

    <items-thumbnails
      v-if="viewType === 'grid'"
      :current-page-prop.sync="currentPage"
      :per-page.sync="perPage"
      :sort-by.sync="sortBy"
      :items="getListItems()"
    />

  </div>
</template>

<script>

// eslint-disable-next-line import/extensions
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import Ripple from 'vue-ripple-directive'
import ItemsListFilters from '@/views/items/items/ItemsListFilters.vue'
import ItemsTable from '@/views/items/items/Items-list/ItemsTable.vue'
import ItemsThumbnails from '@/views/items/items/Items-thumbnail/ItemsThumbnails.vue'
import useTypesList from '@/views/items/filters/filters-list/useTypesList'
import useItemsList from './useItemList'

export default {
  name: 'Items',
  components: {
    ItemsThumbnails,
    ItemsTable,
    ItemsListFilters,
    // NewItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    if (!localStorage.getItem('userSettings')) {
      localStorage.setItem('userSettings', JSON.stringify({ itemView: 'grid' }))
    }
    const viewType = JSON.parse(localStorage.getItem('userSettings')).itemView

    const {
      // Filters
      orderBy,
      prices,
      genders,
      status,
      priceFilter,
      genderFilter,
      statusFilter,
      // rent
      rentFilter,
      rentStatus,

      orderByFilter,
      groupFilter,
      categoryFilter,
      subCategoryFilter,
      typeFilter,
      filterFilter,
      locationFilter,
      locationsOptions,
      /// /////////
      fetchItems,
      getListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // refItemsListTable,
      refetchData,
      // UI
      typePriceResolver,
      statusResolver,
      rentStatusResolver,
      rentStatusTextResolver,
      //
    } = useItemsList()
    fetchItems()

    const {
      fetchCategories,
      getCurrentCategories,
      groupSelect,
      categorySelect,
    } = useCategoriesList()

    const {
      fetchTypes,
      getCurrentTypes,
    } = useTypesList()

    return {
      viewType,
      // Filtri ricerca
      orderBy,
      prices,
      genders,
      status,
      priceFilter,
      genderFilter,
      statusFilter,
      /// rent
      rentFilter,
      rentStatus,

      orderByFilter,
      groupFilter,
      categoryFilter,
      subCategoryFilter,
      typeFilter,
      filterFilter,
      locationFilter,
      locationsOptions,
      /// /////////////
      fetchItems,
      getListItems,
      fetchCategories,
      getCurrentCategories,
      fetchTypes,
      getCurrentTypes,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // refItemsListTable,
      refetchData,
      groupSelect,
      categorySelect,
      // UI
      typePriceResolver,
      statusResolver,
      rentStatusResolver,
      rentStatusTextResolver,
      //
    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>
