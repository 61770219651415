import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isUserLoggedIn } from '@/auth/utils'
import { computed, ref, watch } from '@vue/composition-api'

export default function useRentList() {
  // Use toast
  const toast = useToast()

  const refRentsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'code',
      value: 'code',
      sortable: true,
    },
    {
      key: 'customer',
      value: 'customer',
      sortable: true,
    },
    {
      key: 'items',
      value: 'items',
      sortable: true,
    },
    {
      key: 'shipment_price',
      value: 'shipment_price',
      sortable: true,
    },
    {
      key: 'price',
      value: 'price',
      sortable: true,
    },
    {
      key: 'rent_days',
      value: 'offer',
      sortable: true,
    },
    {
      key: 'status',
      value: 'status',
      sortable: true,
    },
    {
      key: 'status',
      value: 'status',
      sortable: true,
    },
    {
      key: 'forwarded_on',
      value: 'forwarded_on',
      sortable: true,
    },
    { key: 'actions' },
  ]

  const statusOption = [
    {
      label: 'Forwarded - Inoltrato',
      value: 'forwarded',
    },
    {
      label: 'Confirmed - Confermato',
      value: 'confirmed',
    },
    {
      label: 'Paid - Pagato',
      value: 'paid',
    },
    {
      label: 'Delivered - Spedito',
      value: 'delivered',
    },
    {
      label: 'Completed - Completato',
      value: 'completed',
    },
    {
      label: 'Deleted - Cancellato',
      value: 'deleted',
    },
  ]

  const perPage = ref(24)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const fetchRents = (ctx, callback) => {
    if (isUserLoggedIn()) {
      store
        .dispatch('rents/fetchRents', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        })
        .then(response => {
          const { rents, meta } = response.data.data
          totalItems.value = meta.total
          if (callback) {
            callback(rents)
          }
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching rents',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  function fetchRent(id) {
    if (isUserLoggedIn()) {
      store
        .dispatch('rents/fetchRent', id)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching rents',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const dataMeta = computed(() => {
    const localItemsCount = refRentsListTable.value ? refRentsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    fetchRents()
    if (refRentsListTable.value) {
      refRentsListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, isSortDirDesc], () => {
    refetchData()
  })

  const getRents = () => store.getters['rents/getRents']
  const getCurrentRent = () => store.getters['rents/getCurrentRent']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusResolver = currentStatus => {
    if (currentStatus === 'forwarded') return 'warning'
    if (currentStatus === 'confirmed') return 'info'
    if (currentStatus === 'paid') return 'info'
    if (currentStatus === 'delivered') return 'info'
    if (currentStatus === 'completed') return 'success'
    if (currentStatus === 'deleted') return 'danger'
    if (currentStatus === 'revoked') return 'danger'
    return 'secondary'
  }

  return {
    refRentsListTable,
    tableColumns,
    statusOption,
    perPage,
    totalItems,
    currentPage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,
    fetchRents,
    fetchRent,
    refetchData,
    getRents,
    getCurrentRent,
    statusResolver,
  }
}
