<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <b-table
      ref="refItemsListTable"
      class="position-relative mh-200"
      :items="items.products"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No items found"
      :sort-desc.sync="isSortDirDesc"
      @sort-changed="sortingChanged"
    >

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              v-for="filter in row.item.filters"
              :key="filter.id"
              md="3"
              class="mb-1"
            >
              <b-row class="hoverborder py-1">
                <b-col md="8">
                  <div class="d-flex">
                    <div class="ml-0">
                      <span class="d-block">{{ filter.name.en }}</span>
                      <span class="text-muted d-block">
                        {{ filter.name.it }}
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <!-- Column: Code -->
      <template #cell(code)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.code }}</span>
        </div>
      </template>

      <template #cell(title)="data">
        <div class="text-nowrap">
          <b-link :to="{name: 'items.edit', params: { id: data.item.id }}">
            <span class="align-text-top text-capitalize">{{ data.item.title.en }}</span>
            <span class="text-muted d-block">
              {{ data.item.title.it }}
            </span>
          </b-link>
        </div>
      </template>

      <template #cell(category)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.category.en }}</span>
          <span class="text-muted d-block">
            {{ data.item.category.it }}
          </span>
        </div>
      </template>

      <!--      <template #cell(period)="data">-->
      <!--        <div class="text-nowrap">-->
      <!--          <span class="align-text-top text-capitalize">{{ data.item.year }}</span>-->
      <!--        </div>-->
      <!--      </template>-->

      <template #cell(price)="data">
        <div class="text-nowrap">
          <b-badge
            :variant="$parent.typePriceResolver(data.item.type_price)"
            class="badge"
          >
            €{{ data.item.price }}
          </b-badge>
        </div>
      </template>

      <template #cell(brand)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.brand }}</span>
        </div>
      </template>

      <!--      <template #cell(size)="data">-->
      <!--        <div class="text-nowrap">-->
      <!--          <span class="align-text-top text-capitalize">{{ data.item.size }}</span>-->
      <!--        </div>-->
      <!--      </template>-->

      <!--      <template #cell(gender)="data">-->
      <!--        <div class="text-nowrap">-->
      <!--          <span class="align-text-top text-capitalize">{{ data.item.gender }}</span>-->
      <!--        </div>-->
      <!--      </template>-->

      <template #cell(location)="data">
        <div class="text-nowrap">

          <b-dropdown
            v-if="$can('update', 'Product')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <span class="align-text-top text-capitalize">{{ data.item.location }}</span>
            </template>

            <b-dropdown-item
              v-for="location in locationsOptions"
              :key="location.label"
              @click="updateStatus(data.item, {change_location: location.label})"
            >
              <span
                class="align-middle ml-50"
              >{{ location.label }}</span>
            </b-dropdown-item>
          </b-dropdown>

        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">

          <b-dropdown
            v-if="$can('update', 'Product')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template
              #butto
              n-content
            >
              <b-badge
                :variant="$parent.statusResolver(data.item.status)"
                class="badge"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <b-dropdown-item
              @click="updateStatus(data.item, {change_status: 'pending'})"
            >
              <feather-icon icon="BellIcon" />
              <span
                class="align-middle ml-50"
              >Pending</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="updateStatus(data.item, {change_status: 'draft'})"
            >
              <feather-icon icon="Edit3Icon" />
              <span
                class="align-middle ml-50"
              >Draft</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="updateStatus(data.item, {change_status: 'published'})"
            >
              <feather-icon icon="CheckIcon" />
              <span
                class="align-middle ml-50"
              >Published</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="updateStatus(data.item, {change_status: 'archived'})"
            >
              <feather-icon icon="ArchiveIcon" />
              <span class="align-middle ml-50">Archived</span>
            </b-dropdown-item>
          </b-dropdown>

        </div>
      </template>

      <template #cell(images)="data">
        <div class="text-nowrap">
          <span
            class="align-text-top text-capitalize"
          >{{ data.item.gallery }}</span>
          <b-button
            v-if="$can('store', 'Gallery') || $can('update', 'Gallery')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :to="$can('update', 'Product') ? {name: 'items.edit', params: { id: data.item.id }, hash: '#gallery',} : {name: 'items.gallery', params: { id: data.item.id }, hash: '#gallery',}"
            variant="flat-primary"
            class="btn-icon ml-1"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="data.toggleDetails">
            <feather-icon icon="EyeIcon" />
            <span
              class="align-middle ml-50"
            >Details</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'Product')"
            :to="{name: 'items.edit', params: { id: data.item.id }}"
          >
            <feather-icon icon="EditIcon" />
            <span
              class="align-middle ml-50"
            >Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Product')"
            ref="swalConfirmDeleteItem"
            @click="swalConfirmDeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="items && items.meta"
            v-model="currentPage"
            :total-rows="items.meta.total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(val) => $emit('update:currentPageProp', val)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BLink,
  BBadge,

  // BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { useShopUi } from '@/views/items/items/Items-thumbnail/useECommerceShop'
import useCategoriesList from '@/views/items/categories/categories-list/useCategoriesList'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ItemsTable',
  components: {
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BLink,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    orderByFilterProp: {
      type: [String, null],
      default: null,
    },
    locationFilterProp: {
      type: [String, null],
      default: null,
    },
    priceFilterProp: {
      type: [String, null],
      default: null,
    },
    genderFilterProp: {
      type: [String, null],
      default: null,
    },
    statusFilterProp: {
      type: [String, null],
      default: null,
    },
    rentFilterProp: {
      type: [String, null],
      default: null,
    },
    sortByProp: {
      type: [String, null],
      default: null,
    },
    isSortDirDescProp: {
      type: Boolean,
      default: true,
    },
    // Prop filtraggio per categoria
    groupFilterProp: {
      type: [Object, null],
      default: null,
    },
    categoryFilterProp: {
      type: [Array, null],
      default: null,
    },
    subCategoryFilterProp: {
      type: [Array, null],
      default: null,
    },
    //
    // Prop filtraggio per type
    typeFilterProp: {
      type: [Object, null],
      default: null,
    },
    filterFilterProp: {
      type: [Array, null],
      default: null,
    },
    //
    searchQueryProp: {
      type: [String, null],
      default: null,
    },
    currentPageProp: {
      type: Number,
      default: null,
    },
    dataMeta: {
      type: [Object, null],
      default: null,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    locationsOptions: {
      type: [Array, null],
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    tableColumns: {
      type: [Array, null],
      required: true,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
  },
  watch: {
    orderByFilterProp(newVal) {
      this.orderByFilter = newVal
    },
    locationFilterProp(newVal) {
      this.locationFilter = newVal
    },
    locationOptionsFilterProp(newVal) {
      this.orderByFilter = newVal
    },
    priceFilterProp(newVal) {
      this.priceFilter = newVal
    },
    genderFilterProp(newVal) {
      this.genderFilter = newVal
    },
    statusFilterProp(newVal) {
      this.statusFilter = newVal
    },
    rentFilterProp(newVal) {
      this.rentFilter = newVal
    },
    sortByProp(newVal) {
      this.sortBy = newVal
    },
    isSortDirDescProp(newVal) {
      this.isSortDirDesc = newVal
    },
    groupFilterProp(newVal) {
      this.groupFilter = newVal
    },
    categoryFilterProp(newVal) {
      this.categoryFilter = newVal
    },
    subCategoryFilterProp(newVal) {
      this.subCategoryFilter = newVal
    },
    typeFilterProp(newVal) {
      this.typeFilter = newVal
    },
    filterFilterProp(newVal) {
      this.filterFilter = newVal
    },
    searchQueryProp(newVal) {
      this.searchQuery = newVal
    },
    currentPageProp(newVal) {
      this.currentPage = newVal
    },
  },
  mounted() {
    if (this.$can('store', 'Product') || this.$can('update', 'Product')) {
      this.fetchCategories()
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const {
      itemView,
      itemViewOptions,
      totalProducts,
    } = useShopUi()

    const {
      fetchCategories,
      getCurrentCategories,
      groupSelect,
      categorySelect,
    } = useCategoriesList()

    const updateStatus = (item, newData) => {
      store.dispatch('items/updateItem', {
        id: item.id,
        change_status: newData.change_status,
        change_location: newData.change_location,
      })
        .then(response => {
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      updateStatus,

      // UI
      itemView,
      itemViewOptions,
      totalProducts,
      //
      // genders,
      // status,
      orderByFilter: props.orderByFilterProp,
      locationFilter: props.locationFilterProp,
      priceFilter: props.priceFilterProp,
      genderFilter: props.genderFilterProp,
      statusFilter: props.statusFilterProp,
      rentFilter: props.rentFilterProp,
      groupFilter: props.groupFilterProp,
      categoryFilter: props.categoryFilterProp,
      subCategoryFilter: props.subCategoryFilterProp,
      typeFilter: props.typeFilterProp,
      filterFilter: props.filterFilterProp,
      /// //
      groupSelect,
      categorySelect,
      fetchCategories,
      getCurrentCategories,
      currentPage: props.currentPageProp,
      searchQuery: props.searchQueryProp,
      sortBy: props.sortByProp,
      isSortDirDesc: props.isSortDirDescProp,
    }
  },
  methods: {
    swalConfirmDeleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('items/forceDeleteItem', id)
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Item has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Item is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    sortingChanged(ctx) {
      this.$emit('update:sortByProp', ctx.sortBy)
      this.$emit('update:isSortDirDescProp', ctx.sortDesc)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
